//雅曼互动
const postInteractionUrl = `/gateway/hc-serve/miniapi/wechatInteractiveRecord/add`;
//雅曼互动
const myInteractionUrl = `/gateway/hc-serve/miniapi/wechatInteractiveRecord/mineList`;
// 查询用户是否签署领里公约
const signingOfContractDetailsUrl = `/gateway/hc-serve/miniapi/neighborhood/signing-of-contract-details`;
// 获取合约内容
const getNeighborhoodConventionUrl = `/gateway/hc-serve/miniapi/neighborhood/get-neighborhood-convention`;
// 签署领里公约
const signTheContractUrl = `/gateway/hc-serve/miniapi/neighborhood/sign-the-contract`;

// 测试第三方授权接口
const thirdTestUrl = `/gateway/jb-future/jbfuture/getCaseInfoByTicket`;
export {
  postInteractionUrl,
  myInteractionUrl,
  signingOfContractDetailsUrl,
  getNeighborhoodConventionUrl,
  signTheContractUrl,
  thirdTestUrl,
};
